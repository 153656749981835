import React, { FC } from 'react';

import Content from '../../components/base/Content';
import Items from '../../components/generic/Items';
import IconAdd from '../../components/icons/Add';
import administratorsQuery from '../../objects/administrators/queries';
import query from '../../utils/query';
import requiredAuth from '../../utils/requireAuth';

const Administrators = query(Items, administratorsQuery);

const AdministratorsPage: FC = () => (
  <Content>
    <Administrators
      addProps={{
        className: 'mt-6',
        iconLeft: IconAdd,
        size: 's',
        text: 'account.admin.creation.label',
        to: `/admin/account/create/`,
      }}
      fields={[
        {
          className: 'flex-1',
          label: 'Nom',
          name: 'lastName',
        },
        {
          className: 'flex-1',
          label: 'Prénom',
          name: 'firstName',
        },

        {
          className: 'flex-1',
          label: 'user.list.field.createdAt',
          name: 'createdAt',
          type: 'date',
          typeFormat: 'DD/MM/YY',
        },

        {
          className: 'flex-1',
          label: 'user.list.field.email',
          name: 'email',
        },
      ]}
      itemActions={[]}
      pathname="/admin/administrators/"
    />
  </Content>
);

export default requiredAuth(AdministratorsPage, { admin: true, layout: true });
